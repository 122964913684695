<template>
  <el-dialog
    :visible.sync="visible"
    :title="mode ? '新建课程' : '修改课程'"
    class="yt-dialog yt-dialog-default"
    :show-close="!loading"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="formData" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="名称" prop="name">
        <el-input maxlength="80" show-word-limit v-model="formData.name" />
      </el-form-item>
      <el-form-item label="封面" class="cover-item is-required" prop="cover" ref="courseCover">
        <el-upload
          class="cover"
          action=""
          ref="coverUpload"
          accept=".png,.jpg"
          :show-file-list="false"
          :http-request="handleImgSize"
          :on-change="handleChange"
        >
          <template v-if="imageUrl">
            <el-image class="cover" :src="imageUrl"></el-image>
            <div class="upload-update">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-shangchuan" />
              </svg>
              <p>上传</p>
            </div>
          </template>
          <template v-else>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shangchuan" />
            </svg>
            <p>上传</p>
          </template>
        </el-upload>
        <div class="upload-tip">
          <i class="el-icon-warning-outline" style="margin-right: 5px"></i>
          仅支持JPG、PNG格式，10mb以内的图片
        </div>
        <canvas id="uploadCanvas" width="314" height="200" style="display: none"></canvas>
      </el-form-item>
      <el-form-item class="description" label="简介" prop="description">
        <el-input v-model="formData.description" maxlength="200" show-word-limit type="textarea" placeholder="请输入描述" />
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button
        @click="
          visible = false
          clearData()
        "
        class="button"
        >取消</el-button
      >
      <el-button type="primary" class="button" :loading="loading" @click="submit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import ossApi from '@/api/oss'
import courseApi from '@api/course'
export default {
  name: 'CourseCUDialog',
  data() {
    const validateName = (rule, value, callback) => {
      let reg = /^(?=.*\S).+$/
      if (!reg.test(value)) {
        callback(new Error('课程名称不能全为空格'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      mode: true,
      ruleValidate: {
        name: [
          { required: true, message: '课程名称不能为空' },
          { max: 80, message: '课程名称长度不能超过80个字符' },
          { min: 1, message: '课程名称长度不小于1个字符' },
          { validator: validateName, trigger: 'blur' }
        ],
        description: [{ max: 200, message: '课程描述长度不能超过200个字符' }],
        cover: {
          validator: (rule, value, callback) => {
            if (value.length === 0) {
              callback(new Error('请选择课程封面'))
            } else {
              callback()
            }
          }
        }
      },
      formData: {
        categoryId: 0,
        courseId: '',
        name: '',
        description: '',
        cover: '',
        status: 1,
        publicity: 0
      },
      editId: '',
      itCategoryId: '',
      loading: false,
      imageUrl: '',
      uploadFile: {},
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      }
    }
  },
  methods: {
    open(categoryId) {
      this.formData.categoryId = categoryId
      this.visible = true
      this.mode = true
    },
    updateCourse(data) {
      this.formData.name = data.name
      this.imageUrl = data.cover
      this.formData.cover = data.cover
      this.formData.description = data.description
      this.formData.courseId = data.courseId
      this.visible = true
      this.mode = false
    },
    submit() {
      if (this.mode) {
        this.create()
      } else {
        this.update()
      }
    },
    close(done) {
      done()
    },
    handleChange(file) {
      this.uploadFile = file
      this.$refs.courseCover.clearValidate()
    },
    create() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true
          courseApi
            .addCourses(this.formData)
            .then(res => {
              if (res.code === 0) {
                this.visible = false
                this.$message.success('创建成功')
                this.$emit('refresh')
              } else {
                this.loading = false
              }
            })
            .catch(() => {
              this.loading = false
            })
            .finally(() => {
              this.clearData()
            })
        }
      })
    },
    update() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true
          courseApi
            .editCourse(this.formData)
            .then(res => {
              if (res.code === 0) {
                this.$message.success('编辑成功')
                this.visible = false
                this.$emit('refresh')
              }
            })
            .finally(() => {
              this.loading = false
              this.clearData()
            })
        }
      })
    },
    clearData() {
      Object.keys(this.formData).forEach(f => {
        this.formData[f] = null
      })
      this.imageUrl = null
      this.formData['publicity'] = 0
      this.formData['categoryId'] = 0
      this.formData['status'] = 1
    },
    handleImgSize() {
      if (this.uploadFile.size > 10240000) {
        this.$message.warning('请上传小于10mb的图片')
        return
      }
      this.loading = true
      let fileName = this.uploadFile.name.slice(0, -4)
      fileName = fileName.replace(/[ |~`!@#$%^&*()\-_+=\\\[\]{};:"',<.>\/?，。；：“”》《、]/g, '')
      this.uploadFile.name = fileName + (this.uploadFile.raw.type === 'image/png' ? '.png' : '.jpg')
      let c = document.getElementById('uploadCanvas')
      let ctx = c.getContext('2d')
      let reader = new FileReader()
      reader.onloadend = e => {
        let img = new Image()
        img.src = e.target.result
        img.onload = () => {
          ctx.drawImage(img, 0, 0, 314, 200)
          let dataURL = this.uploadFile.raw.type === 'image/png' ? c.toDataURL('image/png') : c.toDataURL('image/jpeg')
          let file = this.$dataUrlToFile(dataURL, this.uploadFile.name)
          if (window.uploadUrl) {
            let forData = new FormData()
            forData.append('file', file, this.uploadFile.name)
            ossApi
              .upCover(forData)
              .then(res => {
                this.imageUrl = res.res
                this.formData.cover = res.res
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            let payload = {
              courseId: new Date().valueOf(),
              name: this.uploadFile.name
            }
            ossApi
              .getCourseCoverToken(payload)
              .then(res => {
                let data = res.res
                this.uploadParams.key = data.dir
                this.uploadParams.OSSAccessKeyId = data.accessId
                this.uploadParams.policy = data.policy
                this.uploadParams.Signature = data.signature
                let formData = new FormData()
                formData.append('key', this.uploadParams.key)
                formData.append('OSSAccessKeyId', this.uploadParams.OSSAccessKeyId)
                formData.append('policy', this.uploadParams.policy)
                formData.append('Signature', this.uploadParams.Signature)
                formData.append('file', file)
                fetch(`https://${data.host}`, {
                  method: 'POST',
                  body: formData
                })
                  .then(result => {
                    this.imageUrl = `https://${data.host}/${this.uploadParams.key}`
                    this.formData.cover = `https://${data.host}/${this.uploadParams.key}`
                  })
                  .finally(() => {
                    this.loading = false
                  })
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      }
      reader.readAsDataURL(this.uploadFile.raw)
    },
    closed() {
      this.loading = false
      this.$refs.form.resetFields()
      this.formData.cover = ''
      this.imageUrl = ''
    }
  }
}
</script>

<style lang="less" scoped>
.cover {
  width: 157px;
  height: 100px;
  ::v-deep .el-upload {
    p {
      height: 13px;
      line-height: 13px;
    }
  }
}
.upload-update {
  p {
    height: 13px;
    line-height: 13px;
  }
}
.upload-tip {
  height: 100px;
  margin-left: 165px;
}
</style>
